import { forwardRef } from 'react';
import Stack from "@mui/material/Stack";
import { Snackbar as MUISnackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Snackbar(props) {
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setSuccessfulUpdate(null);
  };

  const successMessage = "Request submitted.";
  const errorMessage = "Failed to submit your request.";

  return (
    <Stack>
      <MUISnackbar open={props.successfulUpdate} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {props.successMessage || successMessage}
        </Alert>
      </MUISnackbar>
      <MUISnackbar open={props.successfulUpdate !== null && !props.successfulUpdate} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {props.errorMessage || errorMessage}
        </Alert>
      </MUISnackbar>
    </Stack>
  );
}
