import { supportEmail } from "../global";
import Typography from "@mui/material/Typography";
import TwitterIcon from "@mui/icons-material/Twitter";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import {colors} from "../theme";
import PageTitle from "../components/PageTitle";

export default function About() {
  return (
    <>
      <PageTitle title="about" />
      <Typography>
        getGenre provides a highly accurate A.I. for music discovery. Find
        similar music and songs like your favorite artists and albums with{" "}
        <Link href="/spotify-playlists" target="_blank" rel='noreferrer noopener'>
          our Spotify playlists
        </Link>
        .
      </Typography>
      <Typography>
        To learn more or promote your next release, please contact{" "}
        <Link target="_blank" rel='noreferrer noopener' href="/email-support">
          {supportEmail}
        </Link>
        .
      </Typography>
      <Typography
        noWrap
        marginTop="5px"
        marginBottom="15px"
      >
        What genre is
        {" "}<span style={{color:colors.pink, fontWeight:"bold"}}>(</span>
        <span style={{fontWeight:"bold"}}>your favorite type of music</span>
        <span style={{color:colors.pink, fontWeight:"bold"}}>)</span>
        ?
      </Typography>
      <Typography>
        <IconButton href="/twitter" target="_blank" rel='noreferrer noopener'>
          <TwitterIcon />
        </IconButton>
      </Typography>
    </>
  );
}
