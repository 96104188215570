import { Stack, Typography } from "@mui/material";
import { colors, fonts } from "../theme";
import { useState, useRef, useEffect, useContext } from "react";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, useLocation } from "react-router-dom";
import { GenreDataContext } from "./GenreDataContext";
import { getGenreURI } from "../global";

export default function Logo(props) {
  const location = useLocation();

  const genreContext = useContext(GenreDataContext);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  /** TODO this should be global for any other usage
   * Hook that alerts clicks outside of the passed ref
   * UPDATE: MUI has the logic we need: https://mui.com/base/api/click-away-listener/
   */
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleChange = (event, value) => {
    setOpen(false);
    navigate(getGenreURI(value));
  };

  return (
    <Stack alignItems="flex-end" direction="row" ref={wrapperRef} sx={{...props.sx}}>
      {location.pathname !== "/" ? (
        <Link
          href="/"
          underline="none"
          color={colors.white}
        >
          <Typography
            marginBottom="0"
            variant={props.variant}
            fontFamily={fonts.logoFont}
          >
            getGenre
          </Typography>
        </Link>
      ) : (
        <Typography
          marginBottom="0"
          variant={props.variant}
          fontFamily={fonts.logoFont}
        >
          getGenre
        </Typography>
      )}

      <Button
        onClick={() => {
          setOpen(!open);
        }}
        onMouseOver={() => {
          setOpen(true);
        }}
        variant="text"
        color="tertiary"
        sx={{
          padding: "0",
          minWidth: "0"
        }}
      >
        <Typography
          marginBottom="0"
          variant={props.variant}
          fontFamily={fonts.logoFont}
        >
          (
        </Typography>
      </Button>
      {open === true && genreContext && (
        <Autocomplete
          open={open}
          disablePortal
          id="get-genre-search"
          options={genreContext.genres}
          onChange={handleChange}
          sx={{
            width: "45px",
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              variant="standard"
              sx={{marginBottom: "0"}}
              inputProps={{
                ...params.inputProps,
                style: {
                  fontFamily: fonts.boldFont,
                },
              }}
            />
          )}
          PaperComponent={(params) => (
            <Paper sx={{ width: "145px" }} {...params} />
          )}
          ListboxProps={{
            sx: {
              textAlign: "start",
              borderStyle: "solid",
              "&& .MuiAutocomplete-option": {
                "&.Mui-focused": {
                  backgroundColor: colors.pink,
                },
              },
            },
          }}
        />
      )}
      <Button
        onClick={() => {
          setOpen(!open);
        }}
        variant="text"
        color="tertiary"
        onMouseOver={() => {
          setOpen(true);
        }}
        sx={{
          padding: "0",
          minWidth: "0"
        }}
      >
        <Typography
          marginBottom="0"
          variant={props.variant}
          fontFamily={fonts.logoFont}
        >
          )
        </Typography>
      </Button>
      <Typography
        marginBottom="0"
        variant={props.variant}
      >
        ®
      </Typography>
    </Stack>
  );
}
