import Logo from "../components/Logo";
import Tagline from "../components/Tagline";
import Form from "../components/Form";
import { getArtistAlbumURI } from "../global";
import * as Yup from 'yup';
import {fonts} from "../theme";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

const inputFieldNamesAndProperties = {
  'artist': {
    'type': 'text',
    'inputProps': {
      sx: {
        fontFamily: fonts.boldFont,
        textAlign: "center"
      }
    },
    'InputLabelProps': {
      sx: {
        width: "200%",
        textAlign: "center",
      }
    }
  },
  'album': {
    'type': 'text',
    'inputProps': {
      sx: {
        fontFamily: fonts.boldFont,
        textAlign: "center",
      }
    },
    'InputLabelProps': {
      sx: {
        width: "200%",
        textAlign: "center",
      }
    },
    'sx': {
      marginBottom: "16px",
    }
  },
};

const submitButtonProperties = {
  color: 'secondary',
  name: 'Get Genres'
}

const validationSchema = Yup.object().shape({
  'artist': Yup.string().nullable(),
  'album': Yup.string().nullable(),
});
  
export default function Home() {
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSuccess = (result) => {
    const { artist, album } = methods.getValues();

    navigate(getArtistAlbumURI(artist, null, album, null));
  };

  document.title = "getGenre() - The Genre Finder for Music";

  return (
    <>
      <Logo variant="h2" sx={{marginTop: "15px"}}/>
      <Tagline sx={{align:"center", margin:"30px 0"}}/>
      <FormProvider {...methods}>
        <Form
          submitButtonProperties={submitButtonProperties}
          onSuccess={onSuccess}
          inputFieldNamesAndProperties={inputFieldNamesAndProperties}
          disableLoading={true}
        />
      </FormProvider>
    </>
  );
}
