import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export default function ConfirmResetPassword() {
  return (
    <>
      <PageTitle title="Check Your Email" />
      <Typography>
        Please check your email for instructions on how to finish resetting your password.
      </Typography>
      <Typography sx={{marginTop:"16px"}}>
        If you still need help, contact <Link target="_blank" rel='noreferrer noopener' href="/email-support">getGenre support</Link>.
      </Typography>
    </>
  );
}
