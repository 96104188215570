import { createContext, useState, useEffect, useCallback } from "react";
import Cookies from 'universal-cookie';
import axios from 'axios';
import { getGenreAILoginURL, getGenreAILogoutURL } from "../global";

const UserContext = createContext();

const cookies = new Cookies();

const UserContextProvider = ({ children }) => {
  const [loggedIn, setLogin] = useState(null);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);

  const logoutWithAI = useCallback(async ()  => {
    // Only need to log out sessions, which are refresh_tokens; otherwise, access_tokens will naturally expire.
    // Don't use the cookie since the user might not allow cookies, which would cause sessions to never be logged out for a user.
    if (refreshToken) {
      try {
        await axios(
          getGenreAILogoutURL,
          { method: "post", data: {refresh_token: refreshToken}, timeout: 10000 }
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [refreshToken]);

  const logout = useCallback(() => {
    logoutWithAI();
    setLogin(false);
    setUser(null);
    setAccessToken(null);
    setRefreshToken(null);
    cookies.remove('access_token', { path: '/' });
    cookies.remove('refresh_token', { path: '/' });
  }, [logoutWithAI]);

  const login = useCallback((accessToken, refreshToken, userData) => {
    if (userData && accessToken) {
      setUser(userData);
      setAccessToken(accessToken);
      setLogin(true);

      cookies.set('access_token', accessToken, { path: '/' });

      if (refreshToken) {
        setRefreshToken(refreshToken);
        cookies.set('refresh_token', refreshToken, { path: '/' });
      }
    } else {
      // TODO send custom metric/alert since this should never happen and indicates a bug.
      logout();
    }
  }, [logout]);

  const loginWithAI = useCallback(async () => {
    const cookieAccessToken = cookies.get('access_token');
    const cookieRefreshToken = cookies.get('refresh_token');

    if (cookieAccessToken) {
      try {
        const result = await axios(
          getGenreAILoginURL,
          { method: "post", data: {access_token: cookieAccessToken, refresh_token: cookieRefreshToken}, timeout: 10000 }
        );

        login(result.data.access_token, result.data.refresh_token, result.data.user);
      } catch (error) {
        logout();
      }
    } else {
      logout();
    }
  }, [login, logout]);

  useEffect(() => {
    if (loggedIn === null) {
      loginWithAI()
    }
  }, [loggedIn, loginWithAI]);

  return (
    <UserContext.Provider value={{
      loggedIn: loggedIn,
      logout: logout,
      login: login,
      loginWithAI: loginWithAI,
      user: user,
      accessToken: accessToken,
    }}>
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserContextProvider };
