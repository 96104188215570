import { useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import App from "./App";
import Home from "./routes/Home";
import About from "./routes/About";
import SignUp from "./routes/SignUp";
import LogIn from "./routes/LogIn";
import ConfirmResetPassword from "./routes/ConfirmResetPassword";
import ConfirmRegistration from "./routes/ConfirmRegistration";
import RegistrationConfirmation from "./routes/RegistrationConfirmation";
import TermsOfService from "./routes/TermsOfService";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import GenrePage from "./routes/GenrePage";
import { supportEmail } from "./global";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Account from "./routes/Account";
import ArtistPage from "./routes/ArtistPage";
import ArtistAlbum from "./routes/ArtistAlbum";
import ResetPassword from "./routes/ResetPassword";
import { UserContextProvider, UserContext } from './components/UserContext';
import { AppBackdrop } from "./global";

const Redirect = (props) => {
  const location = useLocation();
  let { url } = props;

  if (!url) {
    url = location.pathname.replace('/redirect/','');
  }

  window.location.href = url;

  return (
    <Stack align="center" justifyContent="center" alignItems="center">
      <Typography variant="h4">
        Redirecting to:
      </Typography>
      <Typography variant="h5">
        <a href={url}>{url}</a>
      </Typography>
    </Stack>
  );
};

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};

const AppRoutes = () => {
  const userContext = useContext(UserContext);

  return (
    <Stack>
      {(userContext.loggedIn === null && <AppBackdrop open={true} backgroundColor="rgb(0,0,0,1)"/>) ||
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              index
              element={<Home />}
            />
            <Route
              path="genre/:genre"
              element={<GenrePage />}
            />
            <Route path="artist/:artistName" element={<ArtistPage />}>
              <Route
                path="album/:albumName"
                element={<ArtistAlbum />}
              />
            </Route>
            <Route
              path="confirm-registration"
              element={<ConfirmRegistration />}
            />
            {(!userContext.loggedIn &&
              <>
                <Route
                  path="signup"
                  element={<SignUp />}
                />
                <Route
                  path="login"
                  element={<LogIn />}
                />
                <Route
                  path="reset-password"
                  element={<ResetPassword />}
                />
                <Route
                  path="confirm-reset-password"
                  element={<ConfirmResetPassword />}
                />
                <Route
                  path="registration-confirmation"
                  element={<RegistrationConfirmation />}
                />
              </>) ||
              <>
                <Route
                  path="account"
                  element={<Account />}
                />
              </>
            }
            <Route
              path="about"
              element={<About />}
            />
            <Route
              path="terms-of-service"
              element={<TermsOfService />}
            />
            <Route
              path="privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="email-support"
              element={<Redirect url={"mailto:" + supportEmail} />}
            />
            <Route
              path="spotify-playlists"
              element={<Redirect url={"https://spoti.fi/3mwVQzL"} />}
            />
            <Route
              path="spotify-profile"
              element={<Redirect url={"https://open.spotify.com/user/24n0r8vmgb6ddxtts3lpb8c9a"} />}
            />
            <Route
              path="facebook"
              element={<Redirect url={"https://www.facebook.com/getgenre"} />}
            />
            <Route
              path="twitter"
              element={<Redirect url={"https://twitter.com/getgenre"} />}
            />
            <Route
              path="redirect/*"
              element={<Redirect />}
            />
            <Route
              path="*"
              element={<Navigate to={"/"} replace />}
            />
          </Route>
        </Routes>
      }
    </Stack>
  );
}

const AppRouter = () => {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <ScrollToTop>
          <AppRoutes />
        </ScrollToTop>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default AppRouter;
