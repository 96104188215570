import { useContext } from "react";
import { getGenreAILoginURL } from "../global";
import Form, { setDefaultSubmissionError } from "../components/Form";
import PageTitle from "../components/PageTitle";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import * as Yup from 'yup';
import { UserContext } from "../components/UserContext";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchemaShape = {
  'email': Yup.string()
    .required('Email is required.')
    .email('Email is invalid.'),
  'password': Yup.string()
    .required('Password is required.'),
  'remember_me': Yup.bool()
};

const validationSchema = Yup.object().shape(validationSchemaShape);

const inputFieldNamesAndProperties = {
  'email': {
    'type': 'email',
    'autocomplete': 'email',
  },
  'password': {
    'type': 'password',
    'autocomplete': 'current-password',
    'caption': (<Link href="/reset-password">Forgot your password?</Link>),
  },
  'remember_me': {
    'type': 'checkbox',
    'label': "Remember me",
  },
};

const submitButtonProperties = {
  color: 'secondary',
  name: 'Log In',
};

export default function LogIn() {
  const userContext = useContext(UserContext);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onError = (response, setError, inputFieldNamesAndProperties) => {
    if (response.data && response.data.error) {
      setDefaultSubmissionError(setError, response.data.error);
    } else {
      setDefaultSubmissionError(setError);
    }
  }

  const onSuccess = (result) => {
    if (result.data.access_token && result.data.user && (!methods.getValues("remember_me") || result.data.refresh_token)) {
      userContext.login(result.data.access_token, result.data.refresh_token, result.data.user);
    } else {
      // No need to send metric or alert since integration tests will catch this.
      setDefaultSubmissionError(methods.setError);
    }
  };

  return (
    <>
      <PageTitle title="Log in" />
      <FormProvider {...methods}>
        <Form
          url={getGenreAILoginURL}
          submitButtonProperties={submitButtonProperties}
          onSuccess={onSuccess}
          onError={onError}
          inputFieldNamesAndProperties={inputFieldNamesAndProperties}
        />
      </FormProvider>
      <Typography sx={{marginTop: "16px"}}>
        Need an account? <Link href="/signup">Sign up</Link>.
      </Typography>
    </>
  )
}
