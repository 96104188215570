import Backdrop from '@mui/material/Backdrop';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import weakPasswords from "./weakPasswords";

export const spotifyManagerURL=`https://getgenre.com:6443`;
export const getGenreAIURL=`https://getgenre.ai`;
export const getGenreAILoginURL=`${getGenreAIURL}/accounts/login`;
export const getGenreAILogoutURL=`${getGenreAIURL}/accounts/logout`;
export const supportEmail="support@getgenre.com";
export const undefinedArtist = 'undefinedartist';
export const defaultErrorMessage = "An error occurred. Please try again later.";

export const yupEmailValidations = Yup.string()
  .required('Email is required.')
  .email('Email is invalid.')

export const yupUsernameValidations = Yup.string()
  .required('Username is required.')
  .matches(
    '^[a-zA-Z0-9][a-zA-Z0-9_-]*[a-zA-Z0-9]$',
    'Usernames can only contain alphanumeric characters, underscores, hyphens, and must start and end with an alphanumeric character.'
  )
  .min(6, 'Username must be at least 6 characters.')
  .max(20, 'Username must not exceed 20 characters.')

// TODO let the backend handle weakPasswords instead.
export const yupPasswordValidations = Yup.string()
  .required('Password is required.')
  .min(8, 'Password must be at least 8 characters.')
  .max(40, 'Password must not exceed 40 characters.')
  .matches(
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])',
    'Password must contain lower case (a-z), upper case (A-Z) and numbers (0-9).'
  )
  .notOneOf(weakPasswords, "Password is too weak. Use a stronger one.")

export const getArtistAlbumURI = (artistName, artistID, albumName, albumID) => {
  // URLs won't work without some type of artist.
  if (!artistName) {
    artistName = undefinedArtist;
  }

  const artistEncoded = encodeURIComponent(artistName);

  let uri = `/artist/${artistEncoded}`;

  if (albumName) {
    const albumEncoded = encodeURIComponent(albumName);
    uri = `${uri}/album/${albumEncoded}`;
  }

  if (artistID) {
    uri = `${uri}?artistID=${artistID}`;

    if (albumID) {
      uri = `${uri}&albumID=${albumID}`;
    }
  } else if (albumID) {
    uri = `${uri}?albumID=${albumID}`;
  }

  return uri;
};

export const getGenreURI = (genre) => {
  const genreEncoded = encodeURIComponent(genre.toLowerCase());

  return `/genre/${genreEncoded}`;
};

// https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
export const toTitleCase = (str) => {
  if (str) {
    const articles = ['a', 'an', 'the'];
    const conjunctions = ['for', 'and', 'nor', 'but', 'or', 'yet', 'so'];
    const prepositions = [
      'with', 'at', 'from', 'into','upon', 'of', 'to', 'in', 'for',
      'on', 'by', 'like', 'over', 'plus', 'but', 'up', 'down', 'off', 'near'
    ];

    return str.replace(
      /\w\S*/g,
      function(txt) {
        if (!(articles.includes(txt) || conjunctions.includes(txt) || prepositions.includes(txt))) {
          return txt.charAt(0).toUpperCase() + txt.substr(1);
        } else {
          return txt;
        }
      }
    );
  }
}

export const AppBackdrop = (props) => {
  return (
    <Backdrop
      sx={{
        backgroundColor: props.backgroundColor || "rgb(0,0,0,.5)",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={props.open}
    >
      <CircularProgress />
    </Backdrop>
  )
}

export default getGenreAIURL;
