import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";

export default function RegistrationConfirmation() {
  return (
    <>
      <PageTitle title="Registration Confirmation" />
      <Typography>
        Thank you for registering. Your account is now activated.
      </Typography>
    </>
  );
}
