import { useLocation, Outlet, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';
import Stack from "@mui/material/Stack";
import theme, { colors } from "./theme";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import background from "./images/background.webp";
import Paper from '@mui/material/Paper';
import { GenreDataContextProvider } from './components/GenreDataContext';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Container from '@mui/material/Container';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const goHome = () => {
    navigate("/");
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Stack
        backgroundColor="rgb(0,0,0,1)"
      >
        <Stack
          sx={{
            backgroundImage: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
            padding: "0",
            margin: "auto",
          }}
        >
          <Stack
            sx={{
              background: "linear-gradient(to bottom, transparent, black)",
              backgroundColor: "rgba(0,0,0,.50)",
              width: "100vw",
              minWidth: "250px",
              maxHeight: "100vh",
            }}
          >
            <Stack
              justifyContent="space-between"
              minHeight="100vh"
            >
              <Stack>
                <GenreDataContextProvider>
                  <Nav />
                  {/* Gutters are disabled because weird overflow issues was occuring. */}
                  <Container maxWidth="xl" disableGutters={true}>
                    <Paper
                      elevation={5}
                      sx={{
                        padding: "30px",
                        marginTop: "30px",
                        backgroundImage: "none",
                        backgroundColor: colors.pageBackgroundColor,
                      }}
                    >
                      {location.pathname !== "/" &&
                        <Stack
                          flexDirection="row-reverse"
                          marginBottom="20px"
                        >
                          <IconButton
                            aria-label="Close"
                            onClick={goHome}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      }
                      <Stack
                        align="center"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Outlet />
                      </Stack>
                    </Paper>
                  </Container>
                </GenreDataContextProvider>
              </Stack>
              <Footer />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}
