import { createContext, useEffect, useState } from "react";
import axios from 'axios';
import { AppBackdrop, spotifyManagerURL } from "../global";
import { defaultGenreData } from "../defaultGenreData";

const GenreDataContext = createContext();

const GenreDataContextProvider = ({ children }) => {
  const [genreData, setGenreData] = useState(null);
  const [genreContext, setGenreContext] = useState(null);

  useEffect(() => {
    if (genreData) {
      const genreKeys = Object.keys(genreData);

      let genreList = [];

      for (let i = 0; i < genreKeys.length; i++) {
        genreList.push(genreKeys[i].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()));
      }

      setGenreContext({
        genresAndIds: genreData,
        genres: genreList,
      });
    } else {
      const localGenreData = localStorage.getItem("genreData");

      if (localGenreData) {
        setGenreData(JSON.parse(localGenreData));
      } else {
        setGenreData(defaultGenreData);
      }

      const fetchGenres = async () => {
        try {
          const result = await axios(
            `${spotifyManagerURL}/genres_and_ids`,
            {timeout: 10000}
          );

          setGenreData(result.data);
          localStorage.setItem("genreData", JSON.stringify(result.data));
        } catch (error) {
          console.log(error);
        }
      };

      fetchGenres();
    }
  }, [genreData]);

  return (
    <GenreDataContext.Provider value={genreContext}>
      <AppBackdrop open={!genreContext} />
      {children}
    </GenreDataContext.Provider>
  );
};

export { GenreDataContext, GenreDataContextProvider };
