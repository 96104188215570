import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import Link from "@mui/material/Link";

export default function Footer() {
  return (
    <Stack
      align="center"
    >
      <Typography>
        <small>
          © Copyright {new Date().getFullYear()} getGenre, LLC. All rights
          reserved. <Link href="/terms-of-service">Terms of Service</Link>
          {" | "}
          <Link href="/privacy-policy">Privacy Policy</Link>
        </small>
      </Typography>
    </Stack>
  );
}
