import { useEffect, useContext, useState } from "react";
import Stack from "@mui/material/Stack";
import Backdrop from '@mui/material/Backdrop';
import { GenreDataContext } from "./GenreDataContext";
import CircularProgress from '@mui/material/CircularProgress';

function getPlaylistSource(playlistId) {
  const playlistSource = `https://open.spotify.com/embed/playlist/${playlistId}?utm_source=generator&theme=0`;
  return playlistSource;
}

export default function GenreDetails(props) {
  const genreContext = useContext(GenreDataContext);
  // Should be passed lowercased, but just in case.
  const genre = props.genre.toLowerCase();
  const genreTitle = props.genre.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [genre]);

  return (
    <Stack
      width="100%"
      minWidth="300px"
      maxWidth="500px"
    >
      { genreContext &&
        <Stack position="relative">
          <Backdrop
            sx={{
              position: "absolute"
            }}
            open={loading}
          >
            <CircularProgress />
          </Backdrop>
          <iframe
            key={genre}
            onLoad={(e) => setLoading(false)}
            title={genreTitle + " Playlist"}
            style={{borderRadius: "12px"}}
            src={getPlaylistSource(genreContext.genresAndIds[genre])}
            width="100%"
            height="380"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          />
        </Stack>
      }
    </Stack>
  );
}
