import { useEffect, useState, useContext, useMemo, useCallback } from "react";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import Form, { onSubmit as formOnSubmit, processInputResponseErrors } from "../components/Form";
import { getGenreAIURL, yupUsernameValidations, yupPasswordValidations, yupEmailValidations } from "../global";
import * as Yup from 'yup';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { UserContext } from "../components/UserContext";
import Snackbar from "../components/Snackbar";
import axios from 'axios';
import { AppBackdrop } from "../global";

const clearSessionsKey = "clear_sessions";

const emailValidationSchemaShape = {
  'new_email': yupEmailValidations
};

const passwordValidationSchemaShape = {
  'new_password': yupPasswordValidations
};

const confirmPasswordValidationSchemaShape = {
  'email': yupEmailValidations,
  'password': Yup.string()
    .required('Password is required.'),
};

const usernameValidationSchemaShape = {
  'new_username': yupUsernameValidations
};

const newEmailInputFieldNamesAndProperties = {
  'new_email': {
    'type': 'email',
    'autocomplete': 'email',
    'label': 'New Email'
  },
}

const passwordInputFieldNamesAndProperties = {
  'password': {
    'type': 'password',
    'disabled': true,
    'adornment': false,
    'autocomplete': 'off',
    'inputProps': {
      'readOnly': true,
    }
  },
}

// email2 to avoid conflicting with confirm password email input.
const newPasswordInputFieldNamesAndProperties = {
  'email2': {
    'type': 'email',
    'disabled': true,
    'display': 'none',
    'autocomplete': 'off',
    'inputProps': {
      'readOnly': true,
    }
  },
  'new_password': {
    'type': 'password',
    'autocomplete': 'new-password',
    'label': 'New Password'
  },
}

const usernameInputFieldNamesAndProperties = {
  'username': {
    'type': 'text',
    'disabled': true,
    'autocomplete': 'off',
    'inputProps': {
      'readOnly': true,
    },
    'sx': {
      marginBottom: "16px"
    }
  },
}

const newUsernameInputFieldNamesAndProperties = {
  'new_username': {
    'type': 'text',
    'autocomplete': 'username',
    'label': 'New Username',
    'sx': {
      marginBottom: "16px"
    }
  },
}

const clearAccountSessionsInputFieldNamesAndProperties = {};

const cancelAccountInputFieldNamesAndProperties = {};

const confirmPasswordInputFieldNamesAndProperties = {
  'email': {
    'type': 'email',
    'disabled': true,
    'display': 'none',
    'autocomplete': 'off',
    'inputProps': {
      'readOnly': true,
    },
  },
  'password': {
    'type': 'password',
    'autocomplete': 'current-password',
  },
}

const confirmPasswordSubmitButtonProperties = {
  'name': 'confirm'
};

const emailValidationSchema = Yup.object().shape(emailValidationSchemaShape);
const passwordValidationSchema = Yup.object().shape(passwordValidationSchemaShape);
const confirmPasswordValidationSchema = Yup.object().shape(confirmPasswordValidationSchemaShape);
const usernameValidationSchema = Yup.object().shape(usernameValidationSchemaShape);

const accountSettingsUpdatedMessage = "Account settings updated.";
const emailVerificationSuccessMessage = "Email verification sent.";
const errorMessage = "Failed to process your request.";

export default function Account() {
  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [editUsername, setEditUsername] = useState(false);
  const [userSettings, setUserSettings] = useState(null);
  const [cancelAccount, setCancelAccount] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [confirmPasswordData, setConfirmPasswordData] = useState(null);
  const [confirmPasswordPrompt, setConfirmPasswordPrompt] = useState(null);
  const [clearAllSessions, setClearAllSessions] = useState(null);
  const [successfulUpdate, setSuccessfulUpdate] = useState(null);
  const [copyTitle, setCopyTitle] = useState("Copy");
  const userContext = useContext(UserContext);
  const [successMessage, setSuccessMessage] = useState(accountSettingsUpdatedMessage);
  const [loading, setLoading] = useState(false);

  const resendVerificationEmail = useCallback(async () => {
    const url = `${getGenreAIURL}/accounts/resend_verification_email`;
    const formValues = {
      access_token: userContext.accessToken,
    };

    try {
      await axios(
        url,
        { method: "post", data: formValues, timeout: 10000 }
      );

      setSuccessMessage(emailVerificationSuccessMessage);
      setSuccessfulUpdate(true);
    } catch (error) {
      setSuccessfulUpdate(false);
      console.log(error);
    }
  }, [userContext]);

  const emailInputFieldNamesAndProperties = useMemo(() => {
    return {
      'email': {
        'type': 'email',
        'disabled': true,
        'autocomplete': 'off',
        'inputProps': {
          'readOnly': true,
        },
        'label': (userContext.user.email_verified && 'Email') || (<>Email (<Link onClick={resendVerificationEmail} >pending verification</Link>)</>),
      },
    }
  }, [userContext.user.email_verified, resendVerificationEmail]);

  const emailDefaultValues = useMemo(() => {
    return {
      email: userContext.user.email,
    }
  }, [userContext.user.email]);

  const usernameDefaultValues = useMemo(() => {
    return {
      username: userContext.user.username,
    }
  }, [userContext.user.username]);

  const confirmPasswordDefaultValues = useMemo(() => {
    return {
      email: userContext.user.email,
      password: '',
    }
  }, [userContext]);

  const passwordDefaultValues = {
    password: '************',
  };

  const clearAccountSessionsDefaultValues = {
    clear_sessions: 'true',
  };

  const cancelAccountDefaultValues = {
    cancel_account: 'true',
  };

  const emailMethods = useForm({
    defaultValues: emailDefaultValues,
  });

  const passwordMethods = useForm({
    defaultValues: passwordDefaultValues,
  });

  const usernameMethods = useForm({
    defaultValues: usernameDefaultValues,
  });

  const cancelAccountMethods = useForm({
    defaultValues: cancelAccountDefaultValues,
  });

  const clearAccountSessionsMethods = useForm({
    defaultValues: clearAccountSessionsDefaultValues,
  });

  const newEmailMethods = useForm({
    resolver: yupResolver(emailValidationSchema),
  });

  const newPasswordMethods = useForm({
    resolver: yupResolver(passwordValidationSchema),
  });

  const newUsernameMethods = useForm({
    resolver: yupResolver(usernameValidationSchema),
  });

  const confirmPasswordMethods = useForm({
    defaultValues: confirmPasswordDefaultValues,
    resolver: yupResolver(confirmPasswordValidationSchema),
  });

  const onSuccess = (result) => {
    if (confirmPasswordData.setEditState) {
      confirmPasswordData.setEditState(false);
    }

    confirmPasswordData.methods.reset();
    setSuccessMessage(accountSettingsUpdatedMessage);
    setSuccessfulUpdate(true);

    if (clearAllSessions) {
      userContext.logout();
    } else {
      userContext.loginWithAI();
    }
  };

  const onError = (response, setError, inputFieldNamesAndProperties) => {
    processInputResponseErrors(response, inputFieldNamesAndProperties, setError);
    setSuccessfulUpdate(false);
  };

  const confirmPasswordOnSubmit = (formValues, e) => {
    const url = `${getGenreAIURL}/accounts/edit`;
    formValues = {...confirmPasswordData.formValues, ...formValues}
    formOnSubmit(formValues, confirmPasswordData.e, confirmPasswordData.inputFieldNamesAndProperties, url, confirmPasswordData.setError, onSuccess, onError, setLoading)
    closeAndClearConfirmPassword();
  };

  const onSubmit = (formValues, e, methods, inputFieldNamesAndProperties, setError, modalConfirmPrompt, setEditState) => {
    if (formValues[clearSessionsKey]) {
      setClearAllSessions(true);
    }

    setConfirmPasswordData(
      {
        formValues: formValues,
        e: e,
        methods: methods,
        inputFieldNamesAndProperties: inputFieldNamesAndProperties,
        setError: setError,
        setEditState: setEditState
      }
    );

    if (modalConfirmPrompt) {
      setConfirmPasswordPrompt(modalConfirmPrompt);
    };

    setConfirmPassword(true);
  }

  useEffect(() => {
    confirmPasswordMethods.reset(confirmPasswordDefaultValues);
  }, [confirmPasswordMethods, confirmPasswordDefaultValues]);

  useEffect(() => {
    emailMethods.reset(emailDefaultValues);
    usernameMethods.reset(usernameDefaultValues);
  }, [
    emailMethods,
    usernameMethods,
    emailDefaultValues,
    usernameDefaultValues,
  ]);

  useEffect(() => {
    /* TODO this can be done better... maybe with a single dict param that specifies all that's needed for the given edit/copy/rotate button. */
    const getEditIcons = (methods, inputFieldNamesAndProperties, editState=null, setEditState=null, editable=false, copyable=false, rotatable=false) => {
      let inputToCopy = "";

      if (inputFieldNamesAndProperties) {
        inputToCopy = methods.getValues()[Object.keys(inputFieldNamesAndProperties)[0]];
      }

      return (
        /* 100px width to have a placeholder for submit action */
        /* TODO calculate marginTop dynamically? */
        /* 11px marginTop to account for label heading on TextFields and paddingTop on IconButton, which allows the icons to line up with the input properly. */
        <Stack width="100px" direction="row" alignSelf="flex-start" marginTop="11px">
          {editable &&
            <Stack direction="row">
              {(!editState &&
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setEditState(!editState)}
                    aria-label="edit"
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>) ||
                <Tooltip title="Cancel edit">
                  <IconButton
                    onClick={() => setEditState(!editState)}
                    aria-label="cancel edit"
                  >
                    <EditOffIcon />
                  </IconButton>
                </Tooltip>
              }
              {editState &&
                <Tooltip title="Submit">
                  <IconButton
                    onClick={() => {
                      methods.handleSubmit((formValues, e) => onSubmit(formValues, e, methods, inputFieldNamesAndProperties, methods.setError, null, setEditState))();
                    }}
                    aria-label="Submit"
                  >
                    <DoneIcon />
                  </IconButton>
                </Tooltip>
              }
            </Stack>
          }
          {copyable &&
            <Tooltip title={copyTitle}>
              <IconButton
                onClick={() => {
                  // Note, this will fail if testing with an insecure origin (i.e. http://local.deve)
                  navigator.clipboard.writeText(inputToCopy);
                  setCopyTitle("Copied!");
                }}
                onMouseOut={() => {
                  setCopyTitle("Copy");
                }}
                aria-label="Copy"
              >
                <ContentCopyIcon/>
              </IconButton>
            </Tooltip>
          }
          {rotatable &&
            <Tooltip title="Rotate">
              <IconButton
                onClick={() => {
                  methods.handleSubmit((formValues, e) => onSubmit(formValues, e, methods, inputFieldNamesAndProperties, methods.setError, null, setEditState))();
                }}
                aria-label="Rotate"
              >
                <SyncIcon/>
              </IconButton>
            </Tooltip>
          }
        </Stack>
      )
    }

    const getAccountSetting = (methods, inputFieldNamesAndProperties, setEditState, editIcons, submitButtonProperties, key, confirmPasswordPrompt) => {
      key = key || Object.keys(inputFieldNamesAndProperties)[0];

      const defaultSubmitButtonProperties = {
        'sx': {
          'display': 'none',
        }
      };

      // For now, just use the input name as the key for the Stack.
      /* TODO calculate paddingLeft dynamically */
      /* paddingLeft of 66px to compensate for hidden submit actions (i.e. edit actions have 100px dedicated rendering space, so we subtract the width of the edit button from 100px.) */
      return (
        <Stack key={key} direction="row" alignItems="center" paddingLeft="66px" width="100%" maxWidth="350px">
          <FormProvider {...methods}>
            <Form
              onSubmit={methods.handleSubmit((formValues, e) => onSubmit(formValues, e, methods, inputFieldNamesAndProperties, methods.setError, confirmPasswordPrompt, setEditState))}
              submitButtonProperties={submitButtonProperties || defaultSubmitButtonProperties}
              inputFieldNamesAndProperties={inputFieldNamesAndProperties}
            />
          </FormProvider>
          {editIcons}
        </Stack>
      )
    }

    const editEmailIcons = getEditIcons(newEmailMethods, newEmailInputFieldNamesAndProperties, editEmail, setEditEmail, true);
    const editPasswordIcons = getEditIcons(newPasswordMethods, newPasswordInputFieldNamesAndProperties, editPassword, setEditPassword, true);
    const editUsernameIcons = getEditIcons(newUsernameMethods, newUsernameInputFieldNamesAndProperties, editUsername, setEditUsername);

    let settings = [];

    if (editEmail) {
      settings.push(getAccountSetting(newEmailMethods, newEmailInputFieldNamesAndProperties, setEditEmail, editEmailIcons));
    } else {
      settings.push(getAccountSetting(emailMethods, emailInputFieldNamesAndProperties, setEditEmail, editEmailIcons));
    }

    if (editPassword) {
      settings.push(getAccountSetting(newPasswordMethods, newPasswordInputFieldNamesAndProperties, setEditPassword, editPasswordIcons));
    } else {
      settings.push(getAccountSetting(passwordMethods, passwordInputFieldNamesAndProperties, setEditPassword, editPasswordIcons));
    }

    if (editUsername) {
      settings.push(getAccountSetting(newUsernameMethods, newUsernameInputFieldNamesAndProperties, setEditUsername, editUsernameIcons));
    } else {
      settings.push(getAccountSetting(usernameMethods, usernameInputFieldNamesAndProperties, setEditUsername, editUsernameIcons));
    }

    setUserSettings(settings);

    settings = [];

    const editClearAccountSessionsIcons =  getEditIcons();

    const editCancelAccountIcons =  getEditIcons();

    const getClearAccountSessionsConfirmPasswordPrompt = () => {
      return (
        <Stack>
          <Typography id="modal-log-out-sessions" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
            Confirm Global Account Logout
          </Typography>
          <Typography id="modal-enter-password" sx={{ mt: 2 }}>
            Enter your current password to log out of all devices.
          </Typography>
        </Stack>
      )
    };

    const getCancelAccountConfirmPasswordPrompt = () => {
      return (
        <Stack>
          <Typography id="modal-cancel-account" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
            Confirm Account Cancellation
          </Typography>
          <Typography id="modal-enter-password" sx={{ mt: 2 }}>
            Enter your current password to cancel your account.
          </Typography>
          <Typography id="modal-warning" sx={{ textTransform: 'uppercase', mt: 2, color: 'red', fontWeight: 'bold', marginBottom: "15px" }}>
            Cancelling your account can not be undone!
          </Typography>
        </Stack>
      )
    };

    const clearAccountSessionsSubmitButtonProperties = {
      'name': 'Logout all devices',
      'variant': 'text',
      'color': 'secondary',
      'sx': {
        marginTop: '30px',
      },
    };

    settings.push(getAccountSetting(clearAccountSessionsMethods, clearAccountSessionsInputFieldNamesAndProperties, null, editClearAccountSessionsIcons, clearAccountSessionsSubmitButtonProperties, 'logout', getClearAccountSessionsConfirmPasswordPrompt()));

    const cancelAccountSubmitButtonProperties = {
      'name': 'Cancel my account',
      'variant': 'text',
      'color': 'primary',
    };

    settings.push(getAccountSetting(cancelAccountMethods, cancelAccountInputFieldNamesAndProperties, null, editCancelAccountIcons, cancelAccountSubmitButtonProperties, 'cancel', getCancelAccountConfirmPasswordPrompt()));

    setCancelAccount(settings);
  }, [
    emailInputFieldNamesAndProperties,
    editPassword,
    editEmail,
    editUsername,
    emailMethods,
    newEmailMethods,
    passwordMethods,
    newPasswordMethods,
    usernameMethods,
    newUsernameMethods,
    clearAccountSessionsMethods,
    cancelAccountMethods,
    copyTitle,
  ]);

  const closeAndClearConfirmPassword = () => {
    setConfirmPassword(false);
    setConfirmPasswordPrompt(null);
    setConfirmPasswordData(null);
    // Have to do this here as well as onSuccess() to ensure password gets remove upon error or just closing of the modal in general.
    confirmPasswordMethods.reset(confirmPasswordDefaultValues);
  };

  return (
    <>
      <PageTitle title="My Account" />
      <Typography variant="h5">
        User Settings
      </Typography>
      <Typography sx={{marginBottom: "15px"}}>
        Click on the "<EditIcon fontSize="small" sx={{padding: "2px", verticalAlign: "middle"}} />" icon next to each setting (when available) to update your account details.
      </Typography>
      {userSettings}
      {cancelAccount}
      <Modal
        open={confirmPassword}
        onClose={closeAndClearConfirmPassword}
        aria-labelledby={`modal-confirm-password`}
        aria-describedby={`modal-confirm-password`}
      >
        <Stack
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#3a3a3a",
            boxShadow: 24,
            p: 4,
            outline: "none",
            padding: "15px",
            paddingBottom: "30px",
            paddingLeft: "30px",
            paddingRight: "30px",
            maxHeight: "95vh",
            minWidth: "250px",
            overflow: "auto",
          }}
        >
          <Stack margin="auto" alignItems="center" align="center">
            <Stack
              flexDirection="row-reverse"
              width="100%"
            >
              <Tooltip title="Cancel">
                <IconButton
                  aria-label="Cancel"
                  onClick={closeAndClearConfirmPassword}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            {confirmPasswordPrompt ||
              <Stack>
                <Typography id="modal-account-verification" variant="h6" component="h2" sx={{fontWeight: 'bold'}}>
                  Confirm Account Update
                </Typography>
                <Typography id="modal-enter-password" sx={{ mt: 2, marginBottom: "15px" }}>
                  Enter your current password to confirm your account update.
                </Typography>
              </Stack>
            }
            <FormProvider {...confirmPasswordMethods}>
              <Form
                onSubmit={confirmPasswordMethods.handleSubmit((formValues, e) => confirmPasswordOnSubmit(formValues, e))}
                inputFieldNamesAndProperties={confirmPasswordInputFieldNamesAndProperties}
                submitButtonProperties={confirmPasswordSubmitButtonProperties}
              />
            </FormProvider>
          </Stack>
        </Stack>
      </Modal>
      <Snackbar successfulUpdate={successfulUpdate} setSuccessfulUpdate={setSuccessfulUpdate} successMessage={successMessage} errorMessage={errorMessage} />
      <AppBackdrop open={loading} />
    </>
  );
}
