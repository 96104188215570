import { useEffect, useState } from "react";
import { Outlet as AlbumOutlet, useSearchParams, useParams } from "react-router-dom";
import Search from "../components/Search";

export default function ArtistPage() {
  const [ searchParams, ] = useSearchParams();
  const { artistName, albumName } = useParams();
  const [ artistNameDecoded, setArtistName ] = useState(null);
  const [ artistID, setArtistID ] = useState(null);

  useEffect(() => {
    /* Let AlbumOutlet handle this logic if album search */
    if (!albumName) {
      const decodedArtistNameParam = decodeURIComponent(artistName);
      const artistIDParam = searchParams.get('artistID');

      setArtistID(artistIDParam);
      setArtistName(decodedArtistNameParam);
    }
  }, [artistName, albumName, searchParams]);

  return (
    <>
      {!albumName && artistNameDecoded &&
        <Search artistName={artistNameDecoded} artistID={artistID}/>
      }
      <AlbumOutlet />
    </>
  );
}
