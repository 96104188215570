import { useContext, useState } from "react";
import Link from "@mui/material/Link";
import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";
import Form, { onSubmit as formOnSubmit, onError, setDefaultSubmissionError } from "../components/Form";
import * as Yup from 'yup';
import { getGenreAIURL, yupUsernameValidations, yupPasswordValidations, yupEmailValidations } from "../global";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { UserContext } from "../components/UserContext";
import { AppBackdrop } from "../global";

const validationSchemaShape = {
  'email': yupEmailValidations,
  'password': yupPasswordValidations,
  'username': yupUsernameValidations,
  'birthdate': Yup.string()
    .required('Birthday is required.')
    .test(
      "birthdate",
      "Sorry, you don't meet getGenre's age requirements.",
      function (value) {
        const validAge = new Date()
        validAge.setFullYear(validAge.getFullYear() - 13);
        const timeOverValidAge = dayjs(validAge).diff(value);

        return timeOverValidAge >= 0;
      }
    ),
  'gender': Yup.string()
    .required('Select your gender.'),
  'accept_terms': Yup.bool()
    .oneOf([true], 'Accepting the terms of service is required.')
};

const validationSchema = Yup.object().shape(validationSchemaShape);

export default function SignUp() {
  const userContext = useContext(UserContext);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const inputFieldNamesAndProperties = {
    'email': {
      'type': 'email',
      'autocomplete': 'email',
      'required': true
    },
    'password': {
      'type': 'password',
      'autocomplete': 'new-password',
      'required': true,
    },
    'username': {
      'type': 'text',
      'autocomplete': 'username',
      'required': true
    },
    'birthdate': {
      'type': 'date',
      'label': 'Date of Birth',
      'autocomplete': 'bday',
      'required': true,
      'sx': {
        marginBottom: "16px",
      }
    },
    'gender': {
      'type': 'radio',
      'autocomplete': 'sex',
      'label': "Gender",
      'options': ['female', 'male', 'non-binary', 'other', 'prefer not to say'],
    },
    'accept_terms': {
      'type': 'checkbox',
      'label': (<>I have read and agree to the <Link target="_blank" href="/terms-of-service">Terms of Service</Link>. *</>),
      'caption': (<>To learn more about how getGenre collects, uses, shares and protects your personal data, please see <Link target="_blank" href="/privacy-policy">getGenre's Privacy Policy.</Link></>),
    }
  }

  const submitButtonProperties = {
    color: 'secondary',
    name: "Sign Up"
  };

  const onSuccess = (result) => {
    if (result.data.access_token && result.data.user) {
      userContext.login(result.data.access_token, null, result.data.user);
    } else {
      // No need to send metric or alert since integration tests will catch this.
      setDefaultSubmissionError(methods.setError);
    }

    navigate("/confirm-registration");
  }

  const url = `${getGenreAIURL}/accounts/signup`;

  const onSubmit = (formValues, e) => {
    formValues = {
      ...formValues,
      birthdate: dayjs(formValues['birthdate']).format("MM/DD/YYYY")
    }
    formOnSubmit(formValues, e, inputFieldNamesAndProperties, url, methods.setError, onSuccess, onError, setLoading)
  }

  return (
    <>
      <PageTitle title="Sign Up" />
      <Typography sx={{marginBottom: "15px"}}>
        Perform unlimited search requests and stay up-to-date with upcoming new features, such as the getGenre developer API, by registering below.
      </Typography>
      <FormProvider {...methods}>
        <Form
          url={url}
          onSubmit={onSubmit}
          submitButtonProperties={submitButtonProperties}
          inputFieldNamesAndProperties={inputFieldNamesAndProperties}
        />
      </FormProvider>
      <Typography sx={{marginTop: "16px"}}>
        Have an account? <Link href="/login">Log in</Link>.
      </Typography>
      <AppBackdrop open={loading} />
    </>
  );
}
