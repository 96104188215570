import Form from "../components/Form";
import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";
import * as Yup from 'yup';
import { getGenreAIURL, yupEmailValidations } from "../global";
import Link from "@mui/material/Link";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  'email': yupEmailValidations
});

const inputFieldNamesAndProperties = {
  'email': {
    'type': 'text',
    'label': 'Email Address',
    'sx': {
      marginBottom: "16px",
    }
  },
}

export default function ConfirmRegistration() {
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSuccess = (result) => {
    navigate("/confirm-reset-password");
  };

  return (
    <>
      <PageTitle title="Reset Password" />
      <Typography sx={{marginBottom: "15px"}}>
        Enter the email address you used to register. We'll send you an email with a link to reset your password.
      </Typography>
      <FormProvider {...methods}>
        <Form
          url={`${getGenreAIURL}/accounts/reset_password`}
          inputFieldNamesAndProperties={inputFieldNamesAndProperties}
          onSuccess={onSuccess}
        />
      </FormProvider>
      <Typography sx={{marginTop:"16px"}}>
        If you still need help, contact <Link target="_blank" rel='noreferrer noopener' href="/email-support">getGenre support</Link>.
      </Typography>
    </>
  );
}
