import { useState, useContext, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Container from '@mui/material/Container';
import Box from "@mui/material/Box";
import MenuItem from '@mui/material/MenuItem';
import Toolbar from "@mui/material/Toolbar";
import Logo from "./Logo";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { colors, fonts } from "../theme";
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import { UserContext } from "./UserContext";
import { useNavigate } from "react-router-dom";

/* TODO add aria-label -- also for acccount settings*/
const navMenuItemsAndRoutes = {
  'About': '/about'
};

export default function Nav() {
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [navItems, setNavItems] = useState(null);
  const [navMenuItems, setNavMenuItems] = useState(null);
  const userContext = useContext(UserContext);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  useEffect(() => {
    let items = [];

    for (const [page, route] of Object.entries(navMenuItemsAndRoutes)) {
      items.push(
        <Button
          key={page}
          href={route}
          aria-label={page}
          sx={{
            fontFamily: fonts.boldFont,
          }}
          variant="text"
        >
          {page}
        </Button>
      )
    }

    setNavItems(items);

    items = [];

    for (const [page, route] of Object.entries(navMenuItemsAndRoutes)) {
      items.push(
        <MenuItem
          key={page}
          onClick={() => {
            handleCloseNavMenu();
            navigate(route);
          }}
        >
          <Typography
            textAlign="center"
            sx={{
              fontFamily: fonts.boldFont,
            }}
          >
            {page}
          </Typography>
        </MenuItem>
      )
    }

    setNavMenuItems(items);
  }, [navigate]);

  const goToAccount = () => {
    navigate("/account");
  };

  const logout = () => {
    userContext.logout();
    handleCloseUserMenu();
  };

  return (
    <AppBar
      position="static" 
      sx={{
        backgroundColor: colors.black,
      }}
    >
      <Container
        maxWidth="xl"
      >
        <Toolbar
          disableGutters
        >
          {location.pathname !== "/" && <Logo variant="h4" />}
          <Box
            sx={{
              flexGrow: 1,
            }}
          />
          <Box
            sx={{
              display: {
                xs: 'none',
                md: 'flex'
              },
            }}
          >
            {navItems}
          </Box>
          {(!userContext.loggedIn &&
            <Stack
              direction="row"
            >
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'flex'
                  },
                  margin: "0 15px"
                }}
              >
                <Divider sx={{borderColor: "rgba(255, 255, 255, 0.5)"}} orientation="vertical" variant="middle" flexItem />
              </Box>
              <Button
                href="/signup"
                color="primary"
                sx={{
                  fontFamily: fonts.boldFont,
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                }}
                variant="text"
              >
                Sign up
              </Button>
              <Button
                href="/login"
                color="primary"
                sx={{
                  fontFamily: fonts.boldFont,
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                  marginLeft: "5px",
                }}
                variant="contained"
              >
                Log in
              </Button>
            </Stack>) ||
            <Box
              sx={{
                flexGrow: 0,
                margin: "0 15px"
              }}
            >
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="User" src={userContext.user.picture}/>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="account-menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem key='account' onClick={goToAccount}>
                  <Typography
                    textAlign="center" 
                    sx={{
                      fontFamily: fonts.boldFont,
                    }}
                  >
                    Account
                  </Typography>
                </MenuItem>
                <MenuItem key='log out' onClick={logout}>
                  <Typography
                    textAlign="center" 
                    sx={{
                      fontFamily: fonts.boldFont,
                    }}
                  >
                    Log out
                  </Typography>
                </MenuItem>
              </Menu>
            </Box>
          }
          <Box
            sx={{
              display: {
                xs: 'flex',
                md: 'none'
              },
            }}
          >
            <Tooltip title="Open menu">
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                edge="end"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {navMenuItems}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
