import { useState, useEffect } from "react";
import { toTitleCase, defaultErrorMessage } from "../global";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { fonts } from "../theme";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { useFormContext, Controller } from "react-hook-form";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { AppBackdrop } from "../global";

export const submitErrorKey = 'submit';

export const setDefaultSubmissionError = (setError, message=defaultErrorMessage) => {
  setError(submitErrorKey, {type: 'error', message: message});
  console.log(message);
};

const submit = async (formValues, url, setError, onSuccess, onError, inputFieldNamesAndProperties, setLoading) => {
  if (setLoading) {
    setLoading(true);
  }

  while (true) {
    try {
      if (url) {
        const result = await axios(
          url,
          { method: "post", data: formValues, timeout: 10000 }
        );

        if (result) {
          if (onSuccess) {
            onSuccess(result);
          }
        } else {
          setDefaultSubmissionError(setError);
        }
      } else if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (e.response && onError) {
        onError(e.response, setError, inputFieldNamesAndProperties);
      } else {
        setDefaultSubmissionError(setError);
      }
    }
    break;
  }

  if (setLoading) {
    setLoading(false);
  }
};

export const onSubmit = (formValues, e, inputFieldNamesAndProperties, url, setError, onSuccess, onError, setLoading) => {
  let missingInputs = [];

  for (const [inputFieldName, inputFieldProperties] of Object.entries(inputFieldNamesAndProperties)) {
    let inputValue = "";

    if ((inputFieldProperties['type'] !== 'checkbox') && inputFieldName in formValues) {
      inputValue = formValues[inputFieldName];
    }

    if (!inputValue || inputValue.trim() === "") {
      missingInputs.push(inputFieldName);
    }
  }

  const totalInputs = Object.keys(inputFieldNamesAndProperties).length;

  if (totalInputs > 0 && missingInputs.length === totalInputs) {
    if (setError) {
      setDefaultSubmissionError(setError, "No input provided.");
    }
  } else {
    submit(formValues, url, setError, onSuccess, onError, inputFieldNamesAndProperties, setLoading);
  }
};

export const processInputResponseErrors = (response, inputFieldNamesAndProperties, setError) => {
  if (response && typeof response.data === 'object' && Object.keys(response.data).length > 0) {
    for (const [key, value] of Object.entries(response.data)) {

      let error_string = "error"
      let error_type = "error"

      if (value) {
        if (typeof value === "object") {
          if (value.message) {
            error_string = value.message;
          } else if (value) {
            error_string = JSON.stringify(value);
          }

          if (value.type) {
            error_type = value.type;
          }
        } else {
          error_string = value
        }
      }

      const error_object = {
        type: error_type,
        message: error_string
      };

      if (key in inputFieldNamesAndProperties) {
        setError(key, error_object);
      } else {
        // Need to set an error on a key that get displayed to the user.
        setError(submitErrorKey, error_object);
      }

      console.log(`${key}: ${JSON.stringify(error_object)}`)
    }
  } else {
    // No need to send metric/alert since calling function should handle it as needed.
    setDefaultSubmissionError(setError);
  }
};

export const onError = (response, setError, inputFieldNamesAndProperties) => {
  processInputResponseErrors(response, inputFieldNamesAndProperties, setError);
}

/* TODO add aria-labels/tool tips to inputs and button, especially since input my be cut off */
export default function Form(props) {
  const [showPassword, setShowPassword] = useState(false);
  const [inputFields, setInputFields] = useState(null);
  const [submitButton, setSubmitButton] = useState(null);
  const {register, handleSubmit, control, setError, formState: { errors }} = useFormContext();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInputTextFields = (inputFieldNamesAndProperties) => {
      let formInputs = []

      const getInputType = (type) => {
        if (type === 'password') {
          return showPassword ? "text" : "password";
        } else {
          return type;
        }
      }

      const getInputPropsForInputType = (inputFieldProperties) => {
        const type = inputFieldProperties['type'];
        const additionalInputProps = inputFieldProperties['InputProps'];
        const adornment = inputFieldProperties['adornment'];

        const handleClickShowPassword = () => setShowPassword(!showPassword);

        const handleMouseDownPassword = () => setShowPassword(!showPassword);

        let InputProps = {};

        if (additionalInputProps) {
          InputProps = {...additionalInputProps};
        }

        if (type === 'password' && (adornment || typeof adornment === "undefined")) {
          InputProps = {
            ...InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            )
          }
        }

        return InputProps;
      }

      for (const [inputFieldName, inputFieldProperties] of Object.entries(inputFieldNamesAndProperties)) {
        const inputType = inputFieldProperties['type'];

        if (inputType === 'checkbox') {
          formInputs.push(
            <Stack
              marginBottom="16px"
              key={inputFieldName}
              width="100%"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Controller
                    control={control}
                    required={inputFieldProperties['required']}
                    name={inputFieldName}
                    defaultValue={false}
                    inputRef={register()}
                    render={({ field: { onChange } }) => (
                      <Checkbox
                        color="primary"
                        id={inputFieldName}
                        onChange={e => onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <Typography color={errors[inputFieldName] ? 'error' : 'inherit'} sx={{marginBottom: "0"}}>
                    {inputFieldProperties['label'] || toTitleCase(inputFieldName)}
                  </Typography>
                }
              />
              {errors[inputFieldName] &&
                <FormHelperText
                  error={true}
                  sx={{textAlign: "center", marginBottom: "10px"}}
                >
                  {errors[inputFieldName].message}
                </FormHelperText>
              }
              {inputFieldProperties.caption &&
                <Typography variant="caption" sx={{maxWidth: "450px"}}>
                  {inputFieldProperties.caption}
                </Typography>
              }
            </Stack>
          );
        } else if (inputType === 'radio') {
          let fcls = []

          for (const option of inputFieldProperties['options']) {
            fcls.push(
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={toTitleCase(option)}
                componentsProps={{
                  typography: {
                    sx: {
                      marginBottom: "0"
                    }
                  }
                }}
              />
            );
          }

          formInputs.push(
            <Stack
              marginBottom="16px"
              key={inputFieldName}
            >
              <FormControlLabel
                labelPlacement='top'
                control={
                  <Controller
                    control={control}
                    required={inputFieldProperties['required']}
                    name={inputFieldName}
                    defaultValue={null}
                    inputRef={register()}
                    render={({ field: { onChange } }) => (
                        <RadioGroup
                          row
                          aria-labelledby={`${inputFieldName}-group-label`}
                          defaultValue={null}
                          name={inputFieldName}
                          onChange={e => onChange(e.target.value)}
                          sx={{justifyContent: "center"}}
                        >
                          {fcls}
                        </RadioGroup>
                    )}
                  />
                }
                label={
                  <Stack>
                    <Typography color={errors[inputFieldName] ? 'error' : 'inherit'} sx={{marginBottom: "0", fontFamily: fonts.boldFont}}>
                      {inputFieldProperties['label'] || toTitleCase(inputFieldName)}
                    </Typography>
                    {errors[inputFieldName] &&
                      <FormHelperText
                        error={true}
                      >
                        {errors[inputFieldName].message}
                      </FormHelperText>
                    }
                  </Stack>
                }
              />
              {inputFieldProperties.caption &&
                <Typography variant="caption">
                  {inputFieldProperties.caption}
                </Typography>
              }
            </Stack>
          );
        } else if (inputType === 'date') {
          formInputs.push(
            <Stack
              key={inputFieldName}
              width="100%"
              maxWidth="250px"
              marginBottom="16px"
            >
              <Controller
                name={inputFieldName}
                control={control}
                defaultValue={null}
                render={({
                  field: { onChange, value },
                  fieldState: { error, invalid }
                }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      {...register(inputFieldName)}
                      disableFuture
                      value={value}
                      onChange={(value) =>
                        onChange(dayjs(value))
                      }
                      label={inputFieldProperties['label'] || toTitleCase(inputFieldName)}
                      slotProps={{
                        textField: {
                          id: inputFieldName,
                          name: inputFieldName,
                          error: invalid,
                          autoComplete: inputFieldProperties['autocomplete'],
                          required: inputFieldProperties['required'],
                          helperText: error?.message,
                          inputProps: {
                            sx: {
                              fontFamily: fonts.boldFont,
                            },
                          },
                          sx: inputFieldProperties['sx']
                        },
                        openPickerButton: {
                          sx: {
                            marginRight: "1px"
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Stack>
          );
        } else {
          formInputs.push(
            <Stack
              key={inputFieldName}
              width="100%"
              maxWidth="250px"
              marginBottom="16px"
              display={inputFieldProperties['display']}
            >
              <Controller
                name={inputFieldName}
                control={control}
                defaultValue={null}
                render={({
                  fieldState: { error, invalid }
                }) => (
                  <Stack>
                    <TextField
                      disabled={inputFieldProperties['disabled']}
                      id={inputFieldName}
                      name={inputFieldName}
                      {...register(inputFieldName)}
                      error={invalid}
                      autoComplete={inputFieldProperties['autocomplete']}
                      required={inputFieldProperties['required']}
                      label={inputFieldProperties['label'] || toTitleCase(inputFieldName)}
                      type={getInputType(inputFieldProperties['type'])}
                      helperText={error?.message}
                      InputProps={getInputPropsForInputType(inputFieldProperties)}
                      inputProps={inputFieldProperties['inputProps']}
                      InputLabelProps={inputFieldProperties['InputLabelProps']}
                      sx={inputFieldProperties['sx']}
                    />
                    {inputFieldProperties.caption &&
                      <Typography variant="caption" sx={{textAlign:'left'}}>
                        {inputFieldProperties.caption}
                      </Typography>
                    }
                  </Stack>
                )}
              />
            </Stack>
          );
        }
      }

      return formInputs
    }

    const getSubmitButton = (submitButtonProperties) => {
      return (
        <Controller
          name='submit'
          control={control}
          defaultValue={null}
          render={({
            fieldState: { error, invalid }
          }) => (
            <>
              <Button
                color={submitButtonProperties?.color}
                type="submit"
                disabled={submitButtonProperties?.disabled}
                variant={submitButtonProperties?.variant}
                sx={
                  {
                    ...submitButtonProperties?.sx,
                    fontFamily: fonts.boldFont,
                    marginBottom: "5px",
                  }
                }
              >
                {(submitButtonProperties?.name) || "Submit"}
              </Button>
              {error?.message &&
                <Typography color="error" fontFamily={fonts.boldFont} sx={{maxWidth: "300px"}}>
                  {error.message}
                </Typography>
              }
            </>
          )}
        />
      )
    };

    setInputFields(getInputTextFields(props.inputFieldNamesAndProperties));
    setSubmitButton(getSubmitButton(props.submitButtonProperties));
  }, [props, control, errors, register, showPassword]);

  return (
    <Stack
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={
        (props.onSubmit && handleSubmit(props.onSubmit)) ||
        handleSubmit((formValues, e) => onSubmit(formValues, e, props.inputFieldNamesAndProperties, props.url, setError, props.onSuccess, (props.onError || onError), ((props.disableLoading && null) || setLoading)))
      }
      alignItems="center"
      width="100%"
    >
      {inputFields}
      {submitButton}
      {/* TODO this can be loaded twice if a component is handling its own onSubmit() */}
      <AppBackdrop open={loading} />
    </Stack>
  );
}
