import PageTitle from "../components/PageTitle";
import Typography from "@mui/material/Typography";

export default function ConfirmRegistration() {
  return (
    <>
      <PageTitle title="You're all signed up!" />
      <Typography>
        Look out for an email to validate your account regristration. (Don't forget to check your spam folder!)
      </Typography>
    </>
  );
}
