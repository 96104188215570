import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Search from "../components/Search";
import { undefinedArtist } from "../global";

export default function ArtistAlbum() {
  const [ searchParams ] = useSearchParams();
  const { artistName, albumName } = useParams();
  const [ artistID, setArtistID ] = useState(null);
  const [ albumID, setAlbumID ] = useState(null);
  const [ artistNameDecoded, setArtistName ] = useState(null);
  const [ albumNameDecoded, setAlbumName ] = useState(null);

  useEffect(() => {
    // "undefinedartist" is used when no artist initially provided.
    const decodedArtistNameParam = artistName === undefinedArtist ? "" : decodeURIComponent(artistName);
    const decodedAlbumNameParam = decodeURIComponent(albumName);
    const artistIDParam = searchParams.get('artistID');
    const albumIDParam = searchParams.get('albumID');

    setArtistID(artistIDParam);
    setAlbumID(albumIDParam);
    setArtistName(decodedArtistNameParam);
    setAlbumName(decodedAlbumNameParam);
  }, [artistName, albumName, searchParams]);

  return (
    <>
      {((artistName === undefinedArtist || artistNameDecoded) && albumNameDecoded) &&
        <Search artistName={artistNameDecoded} artistID={artistID} albumName={albumNameDecoded} albumID={albumID} />
      }
    </>
  );
}
